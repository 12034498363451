<template>
    <div id="login-div">
        <div class="title-holder">
            <span>
                Fluke
            </span>
            Portal do distribuidor
        </div>
        <div class="login-card">
            Essa SENHA é TEMPORÁRIA.<br />
            Defina uma nova.<br />
            <br />
            <v-form v-model="isValid" ref="form">
                <v-text-field
                    outlined
                    label="Nova senha"
                    type="password"
                    :rules="[ requiredRule ]"
                    v-model="auth.password" />
                <v-text-field
                    type="password" 
                    outlined
                    :rules="[ requiredRule, mustMatch ]"
                    label="Repita a nova senha"
                    v-model="auth.rePass" />
            </v-form>
        </div>
        <div class="login-card" v-if="!$store.state.auth.user.acceptance">
            <div>
                Você precisa aceitar o termos de compromisso para utilizar o sistema.
                <br />
                <br />
                LINK <br>
                <br>
                <v-checkbox
                    v-model="auth.acceptance"
                    label="Li e aceito o termos de compromissos"
                ></v-checkbox>
            </div>
        </div>
        <div class="btn-aligner">
            <div class="btns-aux"></div>
            <div class="btns-main">
                <v-btn
                    large
                    depressed
                    :disabled="!auth.acceptance"
                    block
                    color="primary"
                    @click="login">

                    DEFINIR
                </v-btn>
            </div>
        </div>
        <!-- <v-progress-linear indeterminate></v-progress-linear> -->
    </div>
</template>

<script>
import validatorRules from '../../helpers/validator_rules'


export default {
    data: () => ({
        auth: {
            password: '',
            rePass: '',
            id: null,
            acceptance: false
        },
        isValid: false
    }),
    computed: {
        requiredRule() {
            return validatorRules.required('Campo obrigatório')
        },
        mustMatch() {
            return validatorRules.confirmPassword( this.auth.password ,'Esse campo está escrito diferente da Nova senha')
        }
    },
    created(){
        this.auth.acceptance = this.$store.state.auth.user.acceptance?true:false
    },
    methods: {
        async login() {
            this.$refs.form.validate()
            if( !this.isValid ) return false

            this.auth.id = this.$store.state.auth.user.id
            const response = await this.$store.dispatch( 'auth/setPassword', this.auth )
            if( response == false ){
                alert('fail')
            } else {
                this.$router.push({
                    name: 'user-list'
                })
            }
        }
    }
}
</script>
